<template>
  <div>
    <div class="warp-register-bg">
      <div class="warp-register-box">
        <div class="box-header">
          <div class="box-title">账号注册</div>
          <back-arrow></back-arrow>
        </div>
        <div class="register-box">
          <div class="box-content-login">
            <van-field
              class="login-num"
              v-model="register.username"
              label-width="25px"
              required
              clearable
              label="账号:"
              :placeholder="userPatternMessage"
            />
          </div>
          <div class="box-content-code">
            <van-field
              class="login-code"
              v-model="register.password"
              type="password"
              label-width="25px"
              clearable
              required
              label="密码:"
              :placeholder="passwordPatternMessage"
            />
          </div>
        </div>
        <div class="rule">
          <div class="rule-content">
            <van-checkbox
              shape="square"
              v-model="registerCheck"
              icon-size="16"
              class="rule-check"
            ></van-checkbox>
            <div class="rule-agreement-read">
              <span>我已阅读并同意</span>
              <span @click="phoneUserProcotol">用户协议</span>
              <span>和</span>
              <span @click="phonePrivacyPolicy">隐私政策</span>
            </div>
          </div>
        </div>
        <div class="box-content-code-login">
          <button @click="userCodeRegister" class="box-content-code-login-btn">
            <span class="box-content-span">账号注册</span>
          </button>
        </div>
        <div class="rule-agreement">
          <span>想要账号更安全?建议使用</span>
          <span @click="phoneRegister">手机注册</span>
        </div>
        <!-- <div class="box-content-other-login">
          <button @click="quickOtherLogin" class="box-content-other-login-btn">
            <span class="box-content-span"
              >其他登录方式 <van-icon name="arrow" />
            </span>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { phoneVerification, phoneLogin } from '@/service/moudules/wrapLogin';
import { userRegister } from '@/service/moudules/wrapLogin';
import { getLocalStorage } from '@/util/userLoginFn';
import backArrow from './components/backArrow.vue';
import { checkInviteCode } from '@/service/moudules/platformApi';
import { Toast } from 'vant';
export default {
  components: {
    backArrow,
  },
  data() {
    return {
      phoneVerification,
      userRegister,
      getLocalStorage,
      checkInviteCode,
      registerCheck: false,
      countdown: 60,
      register: {
        username: '',
        password: '',
      },
      userPattern: /^[A-Za-z0-9]{6,12}$/,
      userPatternMessage: '请输入(6~12个字母或数字)',
      passwordPatternMessage: '请输入(6~12个字母或数字)',
      usernameReg: /^[a-zA-Z0-9]{4,16}$/,
      passwordReg: /^[a-zA-Z0-9]{4,16}$/,
      timer: null,
      inviteCode: '',
    };
  },
  methods: {
    phoneRegister() {
      this.$router.push({
        path: '/phone',
      });
    },
    quickOtherLogin() {
      this.$router.push({
        path: '/wrapLogin',
      });
    },
    phoneUserProcotol() {
      this.$router.push({
        path: '/userProcotol',
      });
    },
    phonePrivacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy',
      });
    },
    async userCodeRegister() {
      try {
        let { username, password } = this.register;
        console.log(username, password);
        if (username === '' && password === '') {
          Toast.fail('请先填写账号密码');
          return false;
        }

        if (!this.usernameReg.test(username)) {
          Toast.fail('账号不符合规则');
          return false;
        }
        if (!this.passwordReg.test(password)) {
          Toast.fail('密码不符合规则');
          return false;
        }
        if (!this.registerCheck) {
          Toast.fail('请先阅读并同意用户协议');
          return false;
        }

        let iParams = {
          inviteCode: this.inviteCode,
        };

        let res = await checkInviteCode(iParams);

        if (res.msg === "false") {
          Toast.fail('邀请码有误,请检查~');
          this.setLocalStorage('inviteCode', '');
          return false;
        }

        let params = {
          account: username,
          password,
          inviteCode: this.inviteCode,
          deviceId: window.localStorage.getItem('browserId')
        };

        let { data, msg, code } = await userRegister(params);

        if (code !== 200) {
          Toast.fail(msg);

          return false;
        }

        Toast.success('账号注册成功~');

        this.timer = setTimeout(() => {
          this.$router.push({
            path: '/account',
          });
          this.register.username = '';
          this.register.password = '';
        }, 1500);
      } catch (error) {
        console.log(error);
      }
    },
    userOtherLogin() {},
    // async pushCode() {
    //   try {
    //     this.isCode = true;
    //     // let timer = setInterval(() => {
    //     //   this.countdown--;
    //     //   if (this.countdown === 0) {
    //     //     this.isCode = false;
    //     //     this.countdown = 60;
    //     //     clearInterval(timer);
    //     //   }
    //     // }, 1000);
    //     let params = {
    //       phone: this.phone.num,
    //     };

    //     let { data, code, msg } = await phoneVerification(params);
    //   } catch (error) {}
    // },
  },
  created() {
    this.inviteCode = this.getLocalStorage('inviteCode');
  },
  destroyed() {
    clearTimeout(this.timer);
  },
};
</script>
<style lang="less" scoped>
.warp-register-bg {
  height: 100vh;
  background-image: url('../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .warp-register-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    width: 345px;
    height: 380px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    // border: 2px solid;
    // border-image: linear-gradient(
    //     224deg,
    //     rgba(255, 255, 255, 0.5),
    //     rgba(255, 255, 255, 0.33),
    //     rgba(255, 255, 255, 0.08)
    //   )
    //   2 2;
    // backdrop-filter: blur(10px);
    .box-header {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-title {
        color: red;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
      }
      .el-icon-question {
        position: absolute;
        top: 23px;
        right: 20px;
        font-size: 18px;
        color: #000;
      }
    }
    .rule-agreement {
      margin-top: 10px;
      color: #000;
      font-size: 14px;
      font-size: 14px;
      font-family: PingFang-SC-Medium;
      & :nth-child(2) {
        color: #815fe7;
        font-size: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium;
      }
    }
    .register-box {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      .box-content-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;

        .login-num {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
        }
      }
      .box-content-code {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #e5e7ed;
        border-radius: 6px;
        width: 294px;
        margin: 20px 0 0 4px;
        .login-code {
          overflow-wrap: break-word;
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: none;
          .push-code {
            color: #7351e9;
          }
        }
      }
    }
    .rule {
      margin-top: 20px;
      .rule-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .rule-check {
          margin-right: 10px;
        }
        .rule-agreement-read {
          color: #000;
          font-size: 14px;
          font-size: 14px;
          font-family: PingFang-SC-Medium;
          & :nth-child(2) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
          & :nth-child(4) {
            color: #815fe7;
            font-size: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Medium;
          }
        }

        .policy {
        }
      }
    }
    .box-content-code-login {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-content-code-login-btn {
        border-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        background-image: linear-gradient(to right, #785fba, #4d53b0, #2e4bb6);
        text-align: center;
        border-radius: 23px;
        border: 2px solid #5266c9;
        margin-top: 24px;
        padding: 6px 90px 6px 90px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #fff;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          white-space: nowrap;
          line-height: 25px;
        }
      }
    }
    .box-content-other-login {
      display: flex;
      justify-content: center;
      align-items: center;
      .box-content-other-login-btn {
        text-align: center;
        border-radius: 23px;
        border: 2px solid #5266c9;
        margin-top: 24px;
        padding: 6px 62px 6px 62px;
        .box-content-span {
          overflow-wrap: break-word;
          color: #7351e9;
          font-size: 18px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          white-space: nowrap;
          line-height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
.van-form {
  width: 100%;
}
</style>
