import '@/service/axios';


/**
 * 
 * 实名认证(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const realAuthentication = params => axios.post('/user/authentication', params).then(data => data)




/**
 * 
 * 手机验证码(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const phoneVerification = params => axios.post('/msg/login/code', params).then(data => data)


/**
 * 
 * 用户手机验证登录(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const phoneLogin = params => axios.post('/user/login', params).then(data => data)



/**
 * 
 * 用户手机验证登录(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const quickLogin = params => axios.post('/user/fastLanding', params).then(data => data)




/**
 * 
 * 用户账号密码注册(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const userRegister = params => axios.post('/user/register', params).then(data => data)


/**
 * 
 * 用户账号密码登录(新)
 * 
 * params:{
 *  id,
 * card,
 * name 
 * }
 * return data(Object)
 */

export const userLogin = params => axios.post('/user/accountLogin', params).then(data => data)

