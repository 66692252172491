<template>
  <div>
    <div @click="back">
      <div class="account-arrow">
        <van-icon name="arrow-left" />
      </div>
    </div>
  </div>
</template>
<script>
import { getLocalStorage } from '@/util/userLoginFn';
export default {
  data() {
    return {
      getLocalStorage,
    };
  },
  methods: {
    back() {
      this.$router.push({
        path: `/wrapLogin?inviteCode=${this.getLocalStorage('inviteCode')}`,
      });
    },
  },
};
</script>
<style lang="less">
.account-arrow {
  position: absolute;
  left: 23px;
  top: 23px;
  font-size: 20px;
  color: #000;
}
</style>
